<template>
  <div>
    <div class="title">user information</div>
    <div class="info">
      <div class="info-item">
        <section>Head portrait:</section>
        <div class="head">
          <img :src="user.avatar" alt="" />
          <div class="edit" @click="edit">Edit Avatar</div>
        </div>
      </div>

      <input
        class="file d-none"
        type="file"
        id="imgupload"
        @change="change($event)"
        accept="image/gif,image/jpeg,image/jpg,image/png,image/bmp,image/webp"
      />

      <div class="info-item">
        <section>* Company Name:</section>
        <div class="input">
          <input type="text" v-model="user.company" @focus="f0" />
          <div class="hint" v-if="hint0">
            The required fields must not be empty
          </div>
        </div>
      </div>

      <div class="info-item">
        <section>* first name:</section>
        <div class="input">
          <input type="text" v-model="user.first_name" @focus="f1" />
          <div class="hint" v-if="hint1">
            The required fields must not be empty
          </div>
        </div>
      </div>

      <div class="info-item">
        <section>* last name:</section>
        <div class="input">
          <input type="text" v-model="user.last_name" @focus="f2" />
          <div class="hint" v-if="hint2">
            The required fields must not be empty
          </div>
        </div>
      </div>

      <div class="info-item">
        <section>* Tax ID:</section>
        <div class="input">
          <input type="text" v-model="user.tax_id" @focus="f3" />
          <div class="hint" v-if="hint3">
            The required fields must not be empty
          </div>
        </div>
      </div>

      <div class="info-item">
        <section>Website:</section>
        <div class="input">
          <input type="text" v-model="user.website" />
        </div>
      </div>

      <div class="info-item">
        <section>Additional Email Address:</section>
        <div class="input">
          <input type="text" v-model="user.additional_email" />
        </div>
      </div>
    </div>
    <div class="button" @click="save">
      <span>save</span>
    </div>
  </div>
</template>
<script>
import { upload } from "network/user";
import { getOrderprofileslist } from "network/user";
export default {
  data() {
    return {
      user: {},
      hint0: false,
      hint1: false,
      hint2: false,
      hint3: false,
      hint4: false,
    };
  },
  created() {
    this.user = this.$store.state.userinfo;
  },
  methods: {
    save() {
      if (this.user.company == "") {
        this.hint0 = true;
        return;
      } else if (this.user.first_name == "") {
        this.hint1 = true;
        return;
      } else if (this.user.last_name == "") {
        this.hint2 = true;
        return;
      } else if (this.user.tax_id == "") {
        this.hint3 = true;
        return;
      }

      let userinfo = {
        avatar: this.user.avatar,
        company: this.user.company,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        tax_id: this.user.tax_id,
        website: this.user.website,
        additional_email: this.user.additional_email,
      };
      getOrderprofileslist(
        this.user.avatar,
        this.user.company,
        this.user.first_name,
        this.user.last_name,
        this.user.tax_id,
        this.user.website,
        this.user.additional_email
      ).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: "success",
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },
    f0() {
      this.hint0 = false;
    },
    f1() {
      this.hint1 = false;
    },
    f2() {
      this.hint2 = false;
    },
    f3() {
      this.hint3 = false;
    },

    edit() {
      document.getElementById("imgupload").click();
    },
    change(event) {
      var data = new FormData();
      data.append("file", event.target.files[0]);
      upload(data).then((res) => {
        if (res.data.code == 1) {
          this.user.avatar = res.data.data.url;
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.title {
  font-size: 0.4rem;
  color: #ff7f00;
  font-size: 600;
  margin-top: 0.5rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #ededed;
}
.info {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ededed;
}
.info-item {
  display: flex;
  color: #4c4c4c;
  font-size: 0.35rem;
  margin-top: 0.5rem;
  align-items: center;
}
.info-item section {
  display: flex;
  flex-wrap: wrap;
  min-width: 3rem;
  max-width: 3rem;
}
.info-item img {
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
}
.info-item input {
  border: 1px solid #e0e0e0;
  width: 10rem;
  margin-left: 0.25rem;
  padding: 0.15rem 0.25rem;
  border-radius: 0.25rem;
}
.head {
  width: 80px;
  height: 80px;
  margin-left: 0.25rem;
  border: 1px solid #ff7f00;
  border-radius: 0.15rem;
  position: relative;
  text-align: center;
}
.edit {
  width: 100%;
  color: #fff;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0 0 0.15rem 0.15rem;
  text-align: center;
  padding: 0.15rem 0;
  cursor: pointer;
}
.hint {
  font-size: 0.35rem;
  color: red;
  margin: 0.1rem 0 0 0.25rem;
}
.button {
  text-align: center;
  margin-bottom: 1rem;
}
.button span {
  color: #ffffff;
  font-size: 0.35rem;
  padding: 0.15rem 0.5rem;
  background: #ff7f00;
  border-radius: 0.15rem;
  cursor: pointer;
}
</style>